







































































































































































































import {
  Component, Vue,
} from 'vue-property-decorator';
import EmergencyFundContributionsViewModel from
  '@/vue-app/view-models/components/goals-dashboard/create-goal/emergency-fund/emergency-fund-contributions-view-model';

@Component({
  name: 'EmergencyFundContributions',
  components: {
    CustomTooltip: () => import('@/vue-app/components/custom/CustomTooltip.vue'),
  },
})
export default class EmergencyFundContributions extends Vue {
  view_model = Vue.observable(
    new EmergencyFundContributionsViewModel(this),
  );

  async created() {
    await this.view_model.initialize();
  }
}
